import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPeopleCarryBox, FaPen, FaPlus } from "react-icons/fa6";
import { useAppContext } from "../../context/AppContextProvider";
import { createUpdateBranch, deleteBranch, getBranchList } from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Select from "../../components/Select";
import TextArea from "../../components/TextArea";
import ConfirmDialog from "../../components/ConfirmDialog";
import Province from "./../../assets/json/province.json";
import City from "./../../assets/json/city.json";

const ManageBranch = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [branchCurrentPage, setBranchCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const { register, setValue, formState: { errors }, reset, clearErrors, handleSubmit } = useForm();

  const branchColumns = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Nama" },
    { key: "province", title: "Provinsi" },
    { key: "city", title: "Kota" },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            setOpenForm(true);
            setDataDetail(item);
            setCityOptions(City?.[item?.province]);
            onChange("name", item?.name);
            onChange("province", item?.province);
            onChange("city", item?.city);
            onChange("address", item?.address);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setDataDetail(item);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetBranchList();
  }, [])

  const onGetBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const response = await getBranchList();
      if (!response.success) throw response.message;

      setIsBranchLoading(false);
      setBranchList(response?.data);
    } catch (error) {
      setIsBranchLoading(false);
      console.log("Error on onGetBranchList: ", error);
    }
  }

  const onCreateUpdateBranch = async (data) => {
    try {
      setIsSubmitLoading(true);
      const request = {
        id: dataDetail?.id,
        name: data?.name || dataDetail?.name,
        province: data?.province || dataDetail?.province,
        city: data?.city || dataDetail?.city,
        address: data?.address || dataDetail?.address
      }
      const response = await createUpdateBranch(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenForm(false);
      onGetBranchList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteBranch(dataDetail?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetBranchList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setIsDeleteLoading(false);
      onOpenAlert("error", error);
    }
  }

  const onOpenForm = () => {
    if (branchList?.length >= userDetail?.branchLimit) {
      onOpenAlert("error", "Max branch limit reached");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setDataDetail({});
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Branch</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isBranchLoading}
            columns={branchColumns}
            dataSource={branchList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={branchCurrentPage}
            total={!isBranchLoading ? branchList?.length : 0}
            onPageChange={(page) => setBranchCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openForm} onOpen={setOpenForm} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onCreateUpdateBranch)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {dataDetail?.id ? <FaPen /> : <FaPeopleCarryBox />}
            </div>
            <div className="ml-3 mr-1 text-lg">{dataDetail?.id ? "Edit" : "Tambah"}</div>
            {dataDetail?.id &&
              <h1 className="mx-1 text-sm">(&nbsp;{dataDetail?.name}&nbsp;)</h1>
            }
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Nama"
                placeholder="Masukkan Nama"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.name?.message}
                onInputChange={(e) => onChange('name', e.target.value)}
                {...register("name", { required: "Nama wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Select
                label="Provinsi"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  option: "capitalize"
                }}
                placeholder="Pilih Provinsi"
                options={Province}
                errorMessage={errors?.province?.message}
                onInputChange={(e) => {
                  onChange('province', e.name);
                  setCityOptions(City[e.name]);
                }}
                {...register("province", { required: "Provinsi wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Select
                label="Kota"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  option: "capitalize"
                }}
                placeholder="Pilih Kota"
                options={cityOptions}
                disabled={!cityOptions?.length}
                errorMessage={errors?.city?.message}
                onInputChange={(e) => onChange('city', e.name)}
                {...register("city", { required: "Kota wajib diisi!" })}
              />
            </div>
            <div className="mb-8">
              <TextArea
                label="Alamat"
                placeholder="Masukkan Alamat"
                className={{
                  label: "text-gray-700",
                  input: "pl-3 resize-none"
                }}
                errorMessage={errors?.address?.message}
                onInputChange={(e) => onChange('address', e.target.value)}
                {...register("address", { required: "Alamat wajib diisi!" })}
              />
            </div>
            <Button label="submit" isLoading={isSubmitLoading} className="w-1/3 h-10 mt-8 ml-auto" />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isBranchLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default ManageBranch;