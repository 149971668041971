import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FaPlus } from "react-icons/fa6";
import { getStockList } from "../../services/api.service";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";

const Stock = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [isStockLoading, setIsStockLoading] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockCurrentPage, setStockCurrentPage] = useState(1);

  const stockColumns = [
    { key: "id", title: "No", render: (val, data, index) => index + 1 },
    { key: "transaction_date", title: "Tanggal", render: (value) => dayjs(value).format('DD MMM YYYY') },
    { key: "product", title: "Nama Barang", render: (value) => value?.name },
    { key: "quantity", title: "Jumlah" },
    { key: "stock_category", title: "Keterangan", render: (value) => value?.name },
    { key: "supplier", title: "Suplier", render: (value) => value?.name || "-" },
    { key: "transfer_branch", title: "Cabang Mutasi", render: (value) => value?.name || "-" }
  ]

  useEffect(() => {
    onGetStockList();
  }, [type])

  const onGetStockList = async () => {
    try {
      setIsStockLoading(true);
      const response = await getStockList(type);
      if (!response.success) throw response.message;

      setIsStockLoading(false);
      setStockList(response.data);
    } catch (error) {
      setIsStockLoading(false);
      console.log("Error on onGetStockList: ", error);
    }
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Stok {type === "in" ? "Masuk" : "Keluar"}</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => navigate("form")}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isStockLoading}
            columns={stockColumns}
            exlucdeColumns={type === "out" ? ["supplier"] : []}
            dataSource={stockList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={stockCurrentPage}
            total={!isStockLoading ? stockList?.length : 0}
            onPageChange={(page) => setStockCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  )
}

export default Stock;