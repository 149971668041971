import { Fragment, useEffect, useState } from "react";
import { FaBoxesPacking, FaPen, FaPlus } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import {
  deleteProductCategory,
  deleteProductUnit,
  getProductCategoryList,
  getProductUnitList,
  createUpdateProductCategory,
  updateProductUnit
} from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Select from "../../components/Select";
import ConfirmDialog from "../../components/ConfirmDialog";

const ManageProduct = () => {
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [isUnitLoading, setIsUnitLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [categoryCurrentPage, setCategoryCurrentPage] = useState(1);
  const [unitCurrentPage, setUnitCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [formType, setFormType] = useState("category");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { register, setValue, formState: { errors }, clearErrors, handleSubmit, reset } = useForm();

  const categoryColumns = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Kategori" },
    { key: "product_unit", title: "Satuan Produk", render: (value) => value?.name },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            reset();
            setFormType("category");
            setOpenForm(true);
            setDataDetail(item);
            onChange("name", item?.name);
            onChange("productUnit.id", item?.product_unit_id);
            onChange("productUnit", item?.product_unit?.name);
          }}
          onDelete={() => {
            setFormType("category");
            setOpenConfirm(true);
            setDataDetail(item);
          }}
        />
      )
    }
  ]

  const unitColumns = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Satuan" },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            reset();
            setFormType("unit");
            setOpenForm(true);
            setDataDetail(item);
            onChange("name", item?.name);
          }}
          onDelete={() => {
            setFormType("unit");
            setOpenConfirm(true);
            setDataDetail(item);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetProductCategoryList();
    onGetProductUnitList();
  }, [])

  const onGetProductCategoryList = async () => {
    try {
      setIsCategoryLoading(true);
      const response = await getProductCategoryList();
      if (!response.success) throw response.message;

      setIsCategoryLoading(false);
      setCategoryList(response.data);
    } catch (error) {
      setIsCategoryLoading(false);
      console.log("Error on onGetProductCategoryList: ", error);
    }
  }

  const onGetProductUnitList = async () => {
    try {
      setIsUnitLoading(true);
      const response = await getProductUnitList();
      if (!response.success) throw response.message;

      setIsUnitLoading(false);
      setUnitList(response.data);
    } catch (error) {
      setIsUnitLoading(false);
      console.log("Error on onGetProductUnitList: ", error);
    }
  }

  const onCreateUpdateCategory = async (data) => {
    try {
      const request = {
        id: dataDetail?.id,
        name: data?.name || dataDetail?.name,
        productUnitId: data?.productUnit?.id || dataDetail?.product_unit_id
      }
      const response = await createUpdateProductCategory(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenForm(false);
      onGetProductCategoryList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpdateCategory: ", error);
    }
  }

  const onCreateUpdateUnit = async (data) => {
    try {
      const request = {
        id: dataDetail?.id,
        name: data?.name || dataDetail?.name
      }
      const response = await updateProductUnit(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenForm(false);
      onGetProductCategoryList();
      onGetProductUnitList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpdateUnit: ", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true);
      if (formType === "category") {
        const response = await deleteProductCategory(dataDetail?.id);
        if (!response.success) throw response.message;
        onGetProductCategoryList();
      } else {
        const response = await deleteProductUnit(dataDetail?.id);
        if (!response.success) throw response.message;
        onGetProductUnitList();
      }
      setDeleteLoading(false);
      setOpenConfirm(false);
    } catch (error) {
      setDeleteLoading(false);
      console.log("Error on onConfirmDelete: ", error);
    }
  }

  const onSubmitForm = (data) => {
    setUpdateLoading(true);
    if (formType === "category") {
      onCreateUpdateCategory(data);
    } else {
      onCreateUpdateUnit(data);
    }
  }

  const onOpenForm = (type) => {
    reset();
    clearErrors();
    setOpenForm(true);
    setFormType(type);
    setDataDetail({});
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Product Category</span>
          <Button
            label="Add Category"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => onOpenForm("category")}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isCategoryLoading}
            columns={categoryColumns}
            dataSource={categoryList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={categoryCurrentPage}
            total={!isCategoryLoading ? categoryList?.length : 0}
            onPageChange={(page) => setCategoryCurrentPage(page)}
          />
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Product Unit</span>
          <Button
            label="Add Unit"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => onOpenForm("unit")}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isUnitLoading}
            columns={unitColumns}
            dataSource={unitList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={unitCurrentPage}
            total={!isUnitLoading ? unitList?.length : 0}
            onPageChange={(page) => setUnitCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openForm} onOpen={setOpenForm} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="flex items-center px-4 py-3 bg-sky-600">
            <div className="p-1 text-sm bg-white rounded-md text-sky-600">
              {dataDetail?.id ? <FaPen /> : <FaBoxesPacking />}
            </div>
            <div className="ml-3 mr-1 text-lg font-semibold text-white">{dataDetail?.id ? "Edit" : "Add"} Form</div>
          </div>
          <div className="px-8 py-6">
            {formType === "unit" &&
              <div className="mb-8">
                <Input
                  label="Satuan Produk"
                  placeholder="Masukkan Satuan"
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3"
                  }}
                  errorMessage={errors?.name?.message}
                  onInputChange={(e) => onChange('name', e.target.value)}
                  {...register("name", { required: "Satuan Produk wajib diisi!" })}
                />
              </div>
            }
            {formType === "category" &&
              <Fragment>
                <div className="mb-8">
                  <Select
                    label="Satuan Produk"
                    placeholder="Pilih Satuan"
                    options={unitList}
                    className={{
                      label: "text-gray-700",
                      input: "h-10 pl-3 capitalize",
                      suffix: "text-sky-700",
                      option: "capitalize"
                    }}
                    errorMessage={errors?.productUnit?.message}
                    onInputChange={(e) => {
                      onChange('productUnit', e.name);
                      onChange('productUnit.id', e.id);
                    }}
                    {...register("productUnit", { required: "Satuan Produk wajib diisi!" })}
                  />
                </div>
                <div className="mb-5">
                  <Input
                    label="Kategori Produk"
                    placeholder="Masukkan Kategori"
                    className={{
                      label: "text-gray-700",
                      input: "h-10 pl-3"
                    }}
                    errorMessage={errors?.name?.message}
                    onInputChange={(e) => onChange('name', e.target.value)}
                    {...register("name", { required: "Kategori Produk wajib diisi!" })}
                  />
                </div>
              </Fragment>
            }
            <Button label="submit" isLoading={updateLoading} className="w-1/3 h-10 mt-8 ml-auto rounded" />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={deleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default ManageProduct;