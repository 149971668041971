import React, { useState, useEffect } from "react";

const LoadingBar = ({ isLoading = false }) => {
  const [progress, setProgress] = useState(0);
  const [hideElmnt, setHideElmnt] = useState(false);

  useEffect(() => {
    let intervalId;
    
    if (isLoading) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress > 90) return prevProgress;
          return prevProgress + (Math.floor(Math.random() * 10) + 1);
        });
      }, 500);
    } else if (progress > 0) {
      setProgress(100);
    }

    return () => clearInterval(intervalId);
  }, [isLoading]);

  useEffect(() => {
    if (progress < 100) return;
    const timeout = setTimeout(() => setHideElmnt(true), 250);
    return () => clearTimeout(timeout);
  }, [progress])

  if (hideElmnt) return null;

  return (
    <div className="w-full h-1 bg-neutral-200">
      <div className="h-1 transition-all ease-out bg-sky-600" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default LoadingBar;
