import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { getBranchList, getReports } from "../../services/api.service";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import InputDate from "../../components/InputDate";
import Select from './../../components/Select';
import Button from './../../components/Button';
import { useAppContext } from './../../context/AppContextProvider';

const filterOptions = [
  { key: "last", name: "Data Terakhir" },
  { key: "date_range", name: "Dengan Tanggal" }
]

const reportTypeOptions = [
  { key: "remain_stock", name: "Sisa Stok", column: "product", exlucdeColumns: [] },
  { key: "mutation_stock", name: "Mutasi Stok", column: "stock", exlucdeColumns: ["supplier"] },
  { key: "stock_in", name: "Stok Masuk", column: "stock", exlucdeColumns: [] },
  { key: "stock_out", name: "Stok Keluar", column: "stock", exlucdeColumns: ["supplier"] },
]

const Report = () => {
  const { userDetail } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [stockReportResult, setStockReportResult] = useState([]);
  const [stockReportCurrentPage, setStockReportCurrentPage] = useState(1);
  const [branchOptions, setBranchOptions] = useState([{ id: 0, name: "Semua Cabang" }]);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [selectedReportType, setSelectedReportType] = useState(reportTypeOptions[0]);
  const [selectedBranch, setSelectedBranch] = useState(branchOptions[0]);
  const dateFromRef = useRef(undefined);
  const dateToRef = useRef(undefined);

  const reportColumns = {
    product: [
      { key: "id", title: "No", render: (val, data, index) => index + 1 },
      { key: "sku_code", title: "Kode produk" },
      { key: "serial_number", title: "Nomor serial" },
      { key: "name", title: "Nama" },
      { key: "category", title: "Kategori" },
      { key: "unit", title: "Satuan" },
      { key: "remain_stock", title: "Stok", render: (val, data) => data?.remain_stock ?? "-" },
      { key: "description", title: "Keterangan" }
    ],
    stock: [
      { key: "id", title: "No", render: (val, data, index) => index + 1 },
      { key: "transaction_date", title: "Tanggal", render: (value) => dayjs(value).format('DD MMM YYYY') },
      { key: "product", title: "Produk", render: (value) => value?.name || "-" },
      { key: "quantity", title: "Jumlah" },
      { key: "stock_type", title: "Tipe Stok", render: (value) => `stock-${value}` },
      { key: "supplier", title: "Suplier", render: (value) => value?.name || "-" },
      { key: "active_branch", title: "Cabang Aktif", render: (value) => value?.name || "-" },
      { key: "transfer_branch", title: "Cabang Mutasi", render: (value) => value?.name || "-" }
    ]
  }

  useEffect(() => {
    onGetBranchList();
  }, [])

  useEffect(() => {
    setStockReportResult([]);
  }, [selectedReportType])

  const onGetBranchList = async () => {
    try {
      const response = await getBranchList();
      if (!response.success) throw response.message;

      setBranchOptions([...branchOptions, ...response.data]);
    } catch (error) {
      console.log("Error on onGetBranchList: ", error);
    }
  }

  const onGetReports = async () => {
    try {
      const startDate = dateFromRef?.current?.onValidateReturnValue();
      const endDate = dateToRef?.current?.onValidateReturnValue();

      if (endDate < startDate || startDate > endDate) {
        alert("Jarak tanggal tidak valid!")
        return;
      }

      setIsLoading(true);
      const request = {
        reportType: selectedReportType.key,
        branchId: selectedBranch.id,
        startDate: dateFromRef?.current?.onValidateReturnValue(),
        endDate: dateToRef?.current?.onValidateReturnValue()
      }
      const response = await getReports(request);
      if (!response.success) throw response.message;

      setIsLoading(false);
      setStockReportResult(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetReports: ", error);
    }
  }

  return (
    <div className="p-6">
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="grid grid-cols-5 gap-4">
          <Select
            label="Tipe Report"
            options={reportTypeOptions}
            defaultValue={reportTypeOptions[0]}
            className={{
              label: "text-gray-700",
              input: "h-10 pl-3 capitalize",
              option: "capitalize"
            }}
            onInputChange={setSelectedReportType}
          />
          <Select
            label="Tipe Filter"
            options={filterOptions}
            defaultValue={filterOptions[0]}
            className={{
              label: "text-gray-700",
              input: "h-10 pl-3 capitalize",
              option: "capitalize"
            }}
            onInputChange={setSelectedFilter}
          />
          {userDetail?.levelName === "owner" &&
            <Select
              label="Pilih Cabang"
              options={branchOptions}
              defaultValue={branchOptions[0]}
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3 capitalize",
                option: "capitalize"
              }}
              onInputChange={setSelectedBranch}
            />
          }
          {selectedFilter?.key !== "date_range" &&
            <Button label="Terapkan Filter" className="h-10 px-3 mt-auto w-fit" onClickBtn={onGetReports} />
          }
        </div>
        {selectedFilter?.key === "date_range" &&
          <div className="grid grid-cols-5 gap-4 mt-4">
            <InputDate ref={dateFromRef} label="Pilih Tanggal Awal" maxDate={new Date()} />
            <InputDate ref={dateToRef} label="Pilih Tanggal Akhir" maxDate={new Date()} />
            <Button label="Terapkan Filter" className="h-10 px-3 mt-auto w-fit" onClickBtn={onGetReports} />
          </div>
        }
        <div className="my-4">
          <Table
            loading={isLoading}
            columns={reportColumns[selectedReportType?.column]}
            exlucdeColumns={selectedReportType?.exlucdeColumns}
            dataSource={stockReportResult}
          />
        </div>
        <div className="my-4">
          <Pagination
            currentPage={stockReportCurrentPage}
            total={!isLoading ? stockReportResult?.length : 0}
            onPageChange={(page) => setStockReportCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  )
}

export default Report;