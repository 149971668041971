import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa6";
import { addedDays, cn } from "../../utils/helper";
import { getLicenseList, getUserList, updateUser } from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Modal from '../../components/Modal';
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";

const ManageOwner = () => {
  const [openEditOwner, setOpenEditOwner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [licenseOption, setLicenseOption] = useState([]);
  const [ownerDetail, setOwnerDetail] = useState({});
  const { register, setValue, formState: { errors }, handleSubmit } = useForm();

  const userColumns = [
    { key: "id", title: "ID", render: (val, item, index) => index + 1 },
    { key: "name", title: "Fullname" },
    { key: "email", title: "Email" },
    { key: "phone_number", title: "Phone Number" },
    { key: "branch_limit", title: "Branch Limit" },
    {
      key: "license",
      title: "License",
      render: (value) => {
        const { name } = value;
        return (
          <div className={cn(
            "capitalize px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
            {
              "text-red-500 bg-red-600/5": name === "trial",
              "text-green-500 bg-green-600/5": name === "premium",
              "text-sky-500 bg-sky-600/5": name === "multibranch"
            }
          )}>
            {name}
          </div>
        )
      }
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          hiddenDelete
          onEdit={() => {
            setOpenEditOwner(true);
            setOwnerDetail(item);
            onChange("license", item?.license?.name);
            onChange("last_period_license", dayjs(item?.period_license).format('DD-MM-YYYY'));
            onChange("branch_limit", item?.branch_limit);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetUserList();
    onGetLicense();
  }, [])

  const onGetUserList = async () => {
    try {
      setFetchLoading(true);
      const response = await getUserList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setUserList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListUser: ", error);
    }
  }

  const onGetLicense = async () => {
    try {
      const response = await getLicenseList();
      if (!response.success) throw response.message;

      setLicenseOption(response.data);
    } catch (error) {
      console.log("Error on onGetLicense: ", error);
    }
  }

  const onUpdateUser = async (data) => {
    if (updateLoading) return;

    try {
      const request = {
        id: ownerDetail?.id,
        licenseId: data?.license?.value || ownerDetail?.license_id,
        periodLicense: addedDays(ownerDetail?.period_license, data.days) || ownerDetail?.period_license,
        branchLimit: data?.branch_limit || ownerDetail?.branch_limit
      }

      setUpdateLoading(true);
      const response = await updateUser(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenEditOwner(false);
      setValue("days", null);
      onGetUserList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpdateUser: ", error);
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="flex-auto bg-white border border-gray-200 rounded-lg h-max shadow-3xl">
      <div className="p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">User Owner List</div>
      <div className="p-4">
        <Table
          loading={fetchLoading}
          columns={userColumns}
          dataSource={userList}
        />
      </div>
      <div className="mb-4">
        <Pagination
          currentPage={currentPage}
          total={!fetchLoading ? userList?.length : 0}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <Modal open={openEditOwner} onOpen={setOpenEditOwner} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onUpdateUser)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg"><FaPen /></div>
            <div className="ml-3 mr-1 text-lg">Edit</div>
            <div className="flex items-center">
              <h1 className="mx-1 text-sm">(&nbsp;{ownerDetail?.name}</h1>
              {(ownerDetail?.name && ownerDetail?.email) && "/"}
              <span className="mx-1 text-sm">{ownerDetail?.email}&nbsp;)</span>
            </div>
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Select
                label="License"
                placeholder="Pilih License"
                options={licenseOption}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize"
                }}
                errorMessage={errors?.license?.message}
                onInputChange={(e) => {
                  onChange('license', e.name);
                  onChange('license.value', e.id);
                }}
                {...register("license")}
              />
            </div>
            <div className="flex mb-5 gap-x-3">
              <div className="basis-6/12">
                <Input
                  label="Last Period License"
                  placeholder="Last Period License"
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3"
                  }}
                  errorMessage={errors?.last_period_license?.message}
                  onInputChange={(e) => onChange('last_period_license', e.target.value)}
                  disabled
                  {...register("last_period_license")}
                />
              </div>
              <div className="basis-6/12">
                <Input
                  label="Period License"
                  placeholder="Period License"
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3"
                  }}
                  errorMessage={errors?.days?.message}
                  onInputChange={(e) => onChange('days', e.target.value)}
                  {...register("days", { pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Mohon masukkan angka.' } })}
                />
              </div>
            </div>
            <div className="mb-8">
              <Input
                label="Max. Branches per User"
                placeholder="User Branches"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.branch_limit?.message}
                onInputChange={(e) => onChange('branch_limit', e.target.value)}
                {...register("branch_limit", { pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Mohon masukkan angka.' } })}
              />
            </div>
            <Button label="submit" isLoading={updateLoading} className="w-1/3 h-10 mt-8 ml-auto" />
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ManageOwner;