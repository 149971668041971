const SSO_TOKEN = 'sso_token';
const LOCAL_TOKEN = 'local_token';
const REACT_APP_ENV = process.env.REACT_APP_ENV;
const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "expires=" + date.toUTCString();
  }
  if (REACT_APP_ENV === "development") {
    document.cookie = `${name}=${value}; ${expires}; path=/;`;
    return;
  }
  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${REACT_APP_COOKIE_DOMAIN};`;
}

const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const getSSOToken = () => {
  return getCookie(SSO_TOKEN);
}

export const getLocalToken = () => {
  return getCookie(LOCAL_TOKEN);
}

export const saveSSOToken = (token) => {
  if (!token) return;
  setCookie(SSO_TOKEN, token, 14)
}

export const saveLocalToken = (token) => {
  if (!token) return;
  setCookie(LOCAL_TOKEN, token, 14)
}

export const destroyToken = () => {
  if (REACT_APP_ENV === "development") {
    document.cookie = `${SSO_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `${LOCAL_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return;
  }
  document.cookie = `${SSO_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${REACT_APP_COOKIE_DOMAIN};`;
  document.cookie = `${LOCAL_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${REACT_APP_COOKIE_DOMAIN};`;
}

export const getLocalStorage = (key) => {
  if (!key) return;

  const data = localStorage.getItem(key);
  if (!data) return;
  
  return JSON.parse(data);
}

export const saveLocalStorage = (key, data = {}) => {
  if (typeof(Storage) === "undefined" || !key) return;
  localStorage.setItem(key, JSON.stringify(data));
}

export const removeLocalStorage = (key) => {
  if (typeof(Storage) === "undefined" || !key) return;
  localStorage.removeItem(key);
}