import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { useAppContext } from './../../context/AppContextProvider';
import { createaStock, getBranchList, getProductList, getStockCategoryList, getSupplierList } from "../../services/api.service";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import InputDate from "../../components/InputDate";
import LoadingBar from "../../components/LoadingBar";

const StockForm = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { userDetail, onOpenAlert } = useAppContext();
  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [productOption, setProductOption] = useState([]);
  const [branchOption, setBranchOption] = useState([]);
  const [stockCategoryOption, setStockCategoryOption] = useState([]);
  const [supplierOption, setSupplierOption] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [productDetail, setProductDetail] = useState({});
  const [isMutation, setIsMutation] = useState(false);
  const { register, trigger, setValue, formState: { errors }, handleSubmit } = useForm();

  const transactionDateRef = useRef(undefined);

  useEffect(() => {
    onFetchAllDataOption();
  }, [])

  useEffect(() => {
    if (productDetail?.quantity >= 0) {
      trigger("quantity");
    }
  }, [JSON.stringify(productDetail)])

  useEffect(() => {
    (isMutation) ? setValue("supplier", undefined) : setValue("transferBranch", undefined);
  }, [isMutation])

  const onFetchAllDataOption = async () => {
    try {
      setIsOptionLoading(true);
      const [productListRes, branchListRes, stockCategoryRes, supplierRes] = await Promise.all([
        getProductList(userDetail?.ownerId),
        getBranchList(userDetail?.ownerId),
        getStockCategoryList(type),
        getSupplierList(userDetail?.ownerId)
      ]);

      setIsOptionLoading(false);
      setProductOption(productListRes?.data);
      setBranchOption(branchListRes?.data);
      setStockCategoryOption(stockCategoryRes?.data);
      setSupplierOption(supplierRes?.data);
    } catch (error) {
      setIsOptionLoading(false);
      console.log("Error on onFetchAllDataOption: ", error);
    }
  }

  const onSubmitStock = async (data) => {
    try {
      const transactionDate = transactionDateRef?.current?.onValidateReturnValue();
      if (!transactionDate) return;

      if (userDetail?.branchId === data?.transferBranch?.id) {
        onOpenAlert("error", "Mutasi tidak bisa di lakukan di cabang yang sama");
        return;
      }

      setIsSubmitLoading(true);
      const request = {
        stockType: type,
        transactionDate: new Date(transactionDate),
        productId: data?.product?.id,
        quantity: !isNaN(parseInt(data?.quantity)) ? parseInt(data?.quantity) : data?.quantity,
        productQuantity: onCalculateQuantity(data?.quantity),
        stockCategoryId: data?.stockCategory?.id,
        supplierId: data?.supplier?.id,
        transferBranchId: data?.transferBranch?.id,
        isMutation: isMutation
      }
      const response = await createaStock(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      navigate(`/stock/${type}`);
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error)
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const onQuantityValidate = (value) => {
    if (type === 'out' && (productDetail?.quantity >= 0 && productDetail?.quantity < value)) {
      return `Jumlah produk melebihi stok tersisa (${productDetail?.quantity})`
    }
    return true;
  }

  const onCalculateQuantity = (quantity = 0) => {
    const lastQuantity = parseInt(productDetail?.quantity);
    const newQuantity = parseInt(quantity);

    if (isNaN(lastQuantity) || isNaN(newQuantity)) return quantity;

    if (lastQuantity >= 0) {
      if (type === 'in') {
        return lastQuantity + newQuantity;
      }
      return lastQuantity - newQuantity;
    }
    return newQuantity;
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex items-center px-2 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <div className="p-4 text-lg cursor-pointer" onClick={() => navigate(`/stock/${type}`)} >
            <FaArrowLeft />
          </div>
          <span>Add Form (stock-{type})</span>
        </div>
        <LoadingBar isLoading={isOptionLoading} />
        <form onSubmit={handleSubmit(onSubmitStock)} className="p-6">
          <div className="grid grid-cols-3 gap-5 mb-5">
            <InputDate ref={transactionDateRef} label="Tanggal Transaksi" errorMessage="Tanggal transaksi wajib diisi!" />
            <Select
              label="Produk"
              placeholder="Pilih Produk"
              options={productOption}
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3 capitalize",
                option: "capitalize"
              }}
              errorMessage={errors?.product?.message}
              onInputChange={(e) => {
                onChange('product', e.name);
                onChange('product.id', e.id);
                setProductDetail(e);
              }}
              {...register("product", { required: "Kategori produk wajib diisi!" })}
            />
            <Input
              label="Jumlah Produk"
              placeholder="Masukkan Jumlah Produk"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.quantity?.message}
              onInputChange={(e) => onChange('quantity', e.target.value)}
              {...register("quantity", {
                required: "Jumlah produk wajib diisi!",
                pattern: { value: /^(\s*|\d+)$/, message: "Jumlah produk harus berupa angka!" },
                validate: (val) => onQuantityValidate(val)
              })}
            />
            <Select
              label="Keterangan Stok"
              placeholder="Pilih Kategori Produk"
              options={stockCategoryOption}
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3 capitalize",
                option: "capitalize"
              }}
              errorMessage={errors?.stockCategory?.message}
              onInputChange={(e) => {
                setIsMutation(e.name?.toLowerCase()?.includes("mutasi"));
                onChange('stockCategory', e.name);
                onChange('stockCategory.id', e.id);
              }}
              {...register("stockCategory", { required: "Keterangan stok wajib diisi!" })}
            />
            {(type === 'in' && !isMutation) &&
              <Select
                label="Suplier"
                placeholder="Pilih Suplier"
                options={supplierOption}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  option: "capitalize"
                }}
                errorMessage={errors?.supplier?.message}
                onInputChange={(e) => {
                  onChange('supplier', e.name);
                  onChange('supplier.id', e.id);
                }}
                {...register("supplier", { required: "Kategori produk wajib diisi!" })}
              />
            }
            {isMutation &&
              <Select
                label="Cabang Mutasi"
                placeholder="Pilih Cabang Mutasi"
                options={branchOption}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  option: "capitalize"
                }}
                errorMessage={errors?.transferBranch?.message}
                onInputChange={(e) => {
                  onChange('transferBranch', e.name);
                  onChange('transferBranch.id', e.id);
                }}
                {...register("transferBranch", { required: "Cabang mutasi wajib diisi!" })}
              />
            }
          </div>
          <div className="flex items-center gap-4 mt-8">
            <Button label="save" isLoading={isSubmitLoading} className="w-40 h-10 px-8" />
            <Button
              label="discard"
              className="w-40 h-10 px-8 text-gray-800 bg-gray-200 hover:bg-gray-500 hover:text-white"
              onClickBtn={() => navigate(`/stock/${type}`)}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default StockForm;