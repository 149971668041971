import { cn } from "../../utils/helper";

export const LoadingIcon = ({ className }) => {
  return (
    <svg className={cn("w-5 h-5 animate-spin", className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );
}

export const SuccessIcon = ({ className }) => {
  return (
    <svg className={cn("w-4 h-4", className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="6" fill="url(#paint0_linear_6_55)" />
      <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_6_55" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#48CA93" />
          <stop offset="1" stopColor="#48BACA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const ErrorIcon = ({ className }) => {
  return (
    <svg className={cn("w-4 h-4", className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="6" fill="url(#paint0_linear_6_67)" />
      <path d="M15 9.00002L9 15M8.99997 9L14.9999 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_6_67" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E88B76" />
          <stop offset="1" stopColor="#CA5048" />
        </linearGradient>
      </defs>
    </svg>
  );
}