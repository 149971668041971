import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { FaCoins, FaPen, FaPlus } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { createUpdateStockCategory, deleteStockCategory, getStockCategoryList } from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import ConfirmDialog from "../../components/ConfirmDialog";

const ManageStock = () => {
  const { type } = useParams();
  const [isStockLoading, setIsStockLoading] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockCurrentPage, setStockCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { register, setValue, formState: { errors }, reset, clearErrors, handleSubmit } = useForm();

  const stockCategoryColumns = [
    { key: "id", title: "No", render: (val, data, index) => index + 1 },
    { key: "name", title: "Kategori" },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            setDataDetail(item);
            setOpenForm(true);
            onChange("category", item?.name);
          }}
          onDelete={() => {
            setDataDetail(item);
            setOpenConfirm(true);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetStockCategoryList();
  }, [type])

  const onGetStockCategoryList = async () => {
    try {
      setIsStockLoading(true);
      const response = await getStockCategoryList(type);
      if (!response.success) throw response.message;

      setIsStockLoading(false);
      setStockList(response.data);
    } catch (error) {
      setIsStockLoading(false);
      console.log("Error on onGetStockCategoryList: ", error);
    }
  }

  const onCreateUpdateStockCategory = async (data) => {
    try {
      setSubmitLoading(true);
      const request = {
        id: dataDetail?.id,
        name: data?.name || dataDetail?.name,
        stockType: type
      }
      const response = await createUpdateStockCategory(request);
      if (!response.success) throw response.message;

      setSubmitLoading(false);
      setOpenForm(false);
      onGetStockCategoryList();
    } catch (error) {
      setSubmitLoading(false);
      console.log("Error on onCreateUpdateStockCategory: ", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteStockCategory(dataDetail?.id);
      if (!response.success) throw response.message;

      setDeleteLoading(false);
      setOpenConfirm(false);
      onGetStockCategoryList();
    } catch (error) {
      setDeleteLoading(false);
      console.log("Error on onConfirmDelete: ", error);
    }
  }

  const onOpenForm = () => {
    reset();
    clearErrors();
    setOpenForm(true);
    setDataDetail({});
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Kategori Stok {type === "in" ? "Masuk" : "Keluar"}</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => onOpenForm("in")}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isStockLoading}
            columns={stockCategoryColumns}
            dataSource={stockList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={stockCurrentPage}
            total={!isStockLoading ? stockList?.length : 0}
            onPageChange={(page) => setStockCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openForm} onOpen={setOpenForm} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onCreateUpdateStockCategory)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {dataDetail?.id ? <FaPen /> : <FaCoins />}
            </div>
            <div className="ml-3 mr-1 text-lg">{dataDetail?.id ? "Edit" : "Tambah"}</div>
            {dataDetail?.id &&
              <h1 className="mx-1 text-sm">(&nbsp;{dataDetail?.name}&nbsp;)</h1>
            }
          </div>
          <div className="px-8 py-6">
            <div className="mb-8">
              <Input
                label="Kategori Stock"
                placeholder="Masukkan Kategori Stock"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.name?.message}
                onInputChange={(e) => onChange('name', e.target.value)}
                {...register("name", { required: "Kategori Stock wajib diisi!" })}
              />
            </div>
            <Button label="submit" isLoading={submitLoading} className="w-1/3 h-10 mt-8 ml-auto" />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={deleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default ManageStock;