import React, { forwardRef } from 'react';
import { cn } from "../utils/helper";

const TextArea = forwardRef(({ label, icon, placeholder, errorMessage, showPassword, onInputChange, className, ...rest }, ref) => {
  return (
    <div className={className?.container}>
      {label && <div className={cn("mb-1 text-sm text-gray-500", className?.label)}>{label}</div>}
      <div className="relative rounded-md shadow-sm">
        {icon &&
          <div className={cn("absolute text-[#5557DB] text-base pointer-events-none left-4 top-1/2 -translate-y-1/2", { "text-red-500": errorMessage }, className?.prefix)}>
            {icon}
          </div>
        }
        <textarea
          {...rest}
          ref={ref}
          className={cn(
            "block w-full h-full py-1.5 pr-1.5 text-sm text-gray-500 border-0 rounded-lg pl-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-[#7D818B]",
            { "placeholder:text-red-300 text-red-900 ring-red-300 focus:ring-red-500": errorMessage },
            className?.input
          )}
          placeholder={placeholder}
          autoComplete="on"
          onChange={(e) => onInputChange?.(e)}
        />
      </div>
      {errorMessage && <p className="mt-1 -mb-5 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
});

export default TextArea;