import Modal from "./Modal";
import Button from "./Button";

const ConfirmDialog = ({ open, onOpen, className, title, detail, cancelText, confirmText, isLoading, onConfirm }) => {
  return (
    <Modal open={open} onOpen={onOpen} className={className}>
      <div className="text-xl font-bold text-black">{title}</div>
      <div className="mt-2 mb-4 font-light text-gray-800">{detail}</div>
      <div className="flex items-center gap-6">
        <Button
          label={cancelText || "Batal"}
          className="h-10 text-gray-800 bg-white ring-1 ring-gray-500 hover:bg-gray-500 hover:text-white"
          onClickBtn={() => onOpen(false)}
        />
        <Button
          label={confirmText || "Konfirmasi"}
          isLoading={isLoading}
          className="h-10 ring-1 ring-sky-600 hover:ring-sky-600/75"
          onClickBtn={onConfirm}
        />
      </div>
    </Modal>
  )
}

export default ConfirmDialog;