import { useNavigate } from 'react-router-dom';
import { destroyToken, removeLocalStorage } from "../../services/jwt.service";
import ConfirmDialog from "../../components/ConfirmDialog";

const Logout = ({ open, onOpen }) => {
  const navigate = useNavigate();

  const onLogout = () => {
    destroyToken();
    removeLocalStorage("user");
    onOpen(false);
    navigate("/login");
  }

  return (
    <ConfirmDialog
      open={open}
      onOpen={onOpen}
      className={{ container: "max-w-sm px-8 py-7" }}
      title="Logout"
      detail="Apakah anda yakin ingin keluar dari akun ini?"
      onConfirm={onLogout}
    />
  )
}

export default Logout;