import React from 'react';
import { cn } from '../utils/helper';
import CircleIcon from '../assets/svg/circle.svg';

const CardCount = ({ title, value, icon, isLoading, className }) => {
  return (
    <div className={cn("relative min-w-[310px] flex flex-col gap-2 bg-gradient-to-tr p-10 rounded-lg shadow", className?.container)}>
      <img alt="" src={CircleIcon} className="absolute bottom-0 right-0 h-full" />
      <div className="flex items-center justify-between">
        <div className="text-lg font-medium text-white">
          {title}
        </div>
        <div className={cn("text-2xl text-white", className?.icon)}>
          {icon}
        </div>
      </div>
      <div className="text-3xl font-semibold text-white">
        {isLoading &&
          <svg className="w-8 h-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        }
        {!isLoading && value}
      </div>
    </div>
  );
};

export default CardCount;