import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft, FaUpload, FaTrashCan } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../context/AppContextProvider";
import { createUpdateProduct, getProductCategoryList, getProductDetail } from "../../services/api.service";
import { getBase64 } from "../../utils/helper";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import TextArea from "../../components/TextArea";
import LoadingBar from "../../components/LoadingBar";

const ProductForm = () => {
  const navigate = useNavigate();
  const inputFileRef = useRef();
  const { productId } = useParams();
  const { onOpenAlert } = useAppContext();
  const [categoriesOption, setCategoriesOption] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isCreateUpdateLoading, setIsCreateUpdateLoading] = useState(false);
  const { register, setValue, formState: { errors }, handleSubmit } = useForm();

  useEffect(() => {
    onFetchProductFormApi();
  }, [])

  const onFetchProductFormApi = async () => {
    try {
      setIsFetchLoading(true);
      const [categoriesListRes, productDetailRes] = await Promise.all([
        getProductCategoryList(),
        productId ? getProductDetail(productId) : Promise.resolve({}),
      ]);

      setIsFetchLoading(false);
      setCategoriesOption(categoriesListRes?.data);
      if (productId) {
        onSetDataDetail(productDetailRes?.data);
      }
    } catch (error) {
      setIsFetchLoading(false);
      console.log("Error on onFetchProductFormApi: ", error);
    }
  }

  const onSetDataDetail = (data = {}) => {
    setDataDetail(data);
    setProductImage(data?.image);
    setValue("skuCode", data?.sku_code);
    setValue("serialNumber", data?.serial_number);
    setValue("name", data?.name);
    setValue("productCategory", data?.product_category?.name);
    setValue("purchasePrice", data?.purchase_price);
    setValue("sellingPrice", data?.selling_price);
    setValue("description", data?.description);
  }

  const onCreateUpdateProduct = async (data) => {
    const productCategory = data?.productCategory?.data;

    try {
      setIsCreateUpdateLoading(true);
      const request = {
        id: productId,
        skuCode: data?.skuCode || dataDetail?.sku_code,
        serialNumber: data?.serialNumber || dataDetail?.serial_number,
        name: data?.name || dataDetail?.name,
        productCategoryId: productCategory?.id || dataDetail?.product_category_id,
        productUnitId: productCategory?.product_unit?.id || dataDetail?.product_unit_id,
        purchasePrice: data?.purchasePrice || dataDetail?.purchase_price,
        sellingPrice: data?.sellingPrice || dataDetail?.selling_price,
        filename: dataDetail?.filename,
        image: productImage || dataDetail?.image,
        description: data?.description || dataDetail?.description
      }
      const response = await createUpdateProduct(request);
      if (!response.success) throw response.message;

      setIsCreateUpdateLoading(false);
      navigate("/product");
    } catch (error) {
      setIsCreateUpdateLoading(false);
      onOpenAlert("error", error)
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const convertImageToBase64 = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const result = await getBase64(file);
      setProductImage(result);
      setDataDetail({ ...dataDetail, filename: file.name });
    } catch (error) {
      console.log("error convertImageToBase64: ", error);
    }
  };

  const onUploadProductImage = () => {
    inputFileRef.current.click()
  }

  const onClearProductImage = (e) => {
    e.stopPropagation();
    setProductImage("");
    inputFileRef.current.value = null;
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex items-center px-2 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <div className="p-4 text-lg cursor-pointer" onClick={() => navigate("/product")} >
            <FaArrowLeft />
          </div>
          <span>{productId ? "Edit" : "Add"} Form</span>
        </div>
        <LoadingBar isLoading={isFetchLoading} />
        <form onSubmit={handleSubmit(onCreateUpdateProduct)} className="p-6">
          <div className="grid grid-cols-3 gap-5 mb-5">
            <Input
              label="Kode SKU"
              placeholder="Masukkan Kode SKU"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.skuCode?.message}
              onInputChange={(e) => onChange('skuCode', e.target.value)}
              {...register("skuCode", { required: "Kode sku wajib diisi!" })}
            />
            <Input
              label="Nomor Serial"
              placeholder="Masukkan Nomor Serial"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.serialNumber?.message}
              onInputChange={(e) => onChange('serialNumber', e.target.value)}
              {...register("serialNumber", { required: "Nomor serial wajib diisi!" })}
            />
            <Input
              label="Nama Produk"
              placeholder="Masukkan Nama Produk"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.name?.message}
              onInputChange={(e) => onChange('name', e.target.value)}
              {...register("name", { required: "Nama produk wajib diisi!" })}
            />
            <Select
              label="Kategori Produk"
              placeholder="Pilih Kategori Produk"
              options={categoriesOption}
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3 capitalize",
                option: "capitalize"
              }}
              errorMessage={errors?.productCategory?.message}
              onInputChange={(e) => {
                onChange('productCategory', e.name);
                onChange('productCategory.data', e);
              }}
              {...register("productCategory", { required: "Kategori produk wajib diisi!" })}
            />
            <Input
              label="Harga Beli"
              placeholder="Masukkan Harga Beli"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.purchasePrice?.message}
              onInputChange={(e) => onChange('purchasePrice', e.target.value)}
              {...register("purchasePrice", { required: "Harga beli wajib diisi!", pattern: { value: /^(\s*|\d+)$/, message: "Harga beli harus berupa angka!" } })}
            />
            <Input
              label="Harga Jual"
              placeholder="Masukkan Harga Jual"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              errorMessage={errors?.sellingPrice?.message}
              onInputChange={(e) => onChange('sellingPrice', e.target.value)}
              {...register("sellingPrice", { required: "Harga jual wajib diisi!", pattern: { value: /^(\s*|\d+)$/, message: "Harga jual harus berupa angka!" } })}
            />
          </div>
          <div className="flex gap-5">
            <input ref={inputFileRef} type="file" hidden onChange={convertImageToBase64} />
            <div className="aspect-[3/2] w-[calc(25%-1rem)]">
              <span className="text-sm text-gray-700">Gambar</span>
              <div className="relative w-full h-full bg-gray-200 rounded-lg cursor-pointer" onClick={onUploadProductImage}>
                {productImage ?
                  <Fragment>
                    <div className="flex items-center justify-center w-full h-full">
                      <img src={productImage} alt="" className="max-h-full rounded-lg" />
                    </div>
                    <div className="absolute top-0 left-0 w-full h-full rounded-lg opacity-0 bg-black/50 hover:opacity-100">
                      <div className="absolute text-white -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" onClick={onClearProductImage}>
                        <FaTrashCan className="mx-auto text-3xl cursor-pointer" />
                        <span>Delete</span>
                      </div>
                    </div>
                  </Fragment>
                  :
                  <div className="absolute text-gray-500 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                    <FaUpload className="mx-auto text-3xl" />
                    <span>Upload</span>
                  </div>
                }
              </div>
            </div>
            <TextArea
              label="Deskripsi"
              placeholder="Masukkan Deskripsi"
              className={{
                container: "w-[calc(25%-1rem)]",
                label: "text-gray-700",
                input: "pl-3 resize-none aspect-[3/2]"
              }}
              errorMessage={errors?.description?.message}
              onInputChange={(e) => onChange('description', e.target.value)}
              {...register("description")}
            />
          </div>
          <div className="flex items-center gap-4 mt-8">
            <Button label="save" isLoading={isCreateUpdateLoading} className="w-40 h-10 px-8" />
            <Button
              label="discard"
              className="w-40 h-10 px-8 text-gray-800 bg-gray-200 hover:bg-gray-500 hover:text-white"
              onClickBtn={() => navigate("/product")}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductForm;