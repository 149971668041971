import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChartSimple, FaWarehouse, FaUsers, FaPeopleCarryBox, FaBoxesPacking, FaBagShopping, FaHockeyPuck, FaFileLines } from "react-icons/fa6";
import { RiInboxArchiveFill, RiInboxUnarchiveFill } from "react-icons/ri";
import { cn } from "../utils/helper";
import { useAppContext } from "../context/AppContextProvider";
import LogoImg from "./../assets/image/sistok-logo-white.png";

const MENUS = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    level: ["superadmin", "owner", "staff"],
    license: "standard",
    link: "/dashboard"
  },
  {
    title: "Category Stock In",
    icon: <RiInboxArchiveFill />,
    type: "menu",
    level: ["superadmin"],
    license: "standard",
    link: "/category-stock/in"
  },
  {
    title: "Category Stock Out",
    icon: <RiInboxUnarchiveFill />,
    type: "menu",
    level: ["superadmin"],
    license: "standard",
    link: "/category-stock/out"
  },
  {
    title: "Manage Product",
    icon: <FaBoxesPacking />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/manage-product"
  },
  {
    title: "Product",
    icon: <FaBagShopping />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/product"
  },
  {
    title: "Suplier",
    icon: <FaPeopleCarryBox />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/suplier"
  },
  {
    title: "Stock In",
    icon: <FaHockeyPuck />,
    type: "menu",
    level: ["staff"],
    license: "standard",
    link: "/stock/in"
  },
  {
    title: "Stock Out",
    icon: <FaHockeyPuck />,
    type: "menu",
    level: ["staff"],
    license: "standard",
    link: "/stock/out"
  },
  {
    title: "Branch",
    icon: <FaWarehouse />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/branch"
  },
  {
    title: "Staff",
    icon: <FaUsers />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/staff"
  },
  {
    title: "Report",
    icon: <FaFileLines />,
    type: "menu",
    level: ["owner", "staff"],
    license: "standard",
    link: "/report"
  }
]

const Sider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail, setStore } = useAppContext();
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  useEffect(() => {
    const menuTitle = MENUS.find(menu => location.pathname?.includes(menu.link))?.title;
    setStore({ menuTitle });
  }, [])

  const onClickMenu = ({ title, link }) => {
    setStore({ menuTitle: title });
    setActiveMenu(link);
    navigate(link);
  }

  const renderMenus = () => {
    return MENUS.map((menu, index) => {
      if (!menu.level.includes(userDetail?.levelName)) return null;

      return (
        <li
          key={index}
          className={cn(
            "flex gap-2 p-2 m-2 rounded-md text-white list-none cursor-pointer select-none hover:bg-white/10 hover:font-bold",
            { "bg-white text-sky-700 font-bold hover:bg-white hover:text-sky-700": activeMenu?.includes(menu.link) }
          )}
          onClick={() => onClickMenu(menu)}
        >
          {menu.icon}
          <span className="text-sm">{menu.title}</span>
        </li>
      )
    })
  }

  return (
    <aside className="min-w-[240px] min-h-screen bg-gradient-to-b from-sky-700 to-sky-500">
      <a href="/dashboard" className="flex justify-center p-6 text-center">
        <img alt="" src={LogoImg} className="h-9" />
      </a>
      <hr className="mx-4 mb-6 border-t-white/20" />
      {renderMenus()}
    </aside>
  )
}

export default Sider;