import { Navigate } from "react-router-dom";
import { destroyToken, getLocalStorage, getLocalToken, getSSOToken, removeLocalStorage } from "../services/jwt.service";
import { periodLicenseValidator } from "../utils/helper";

const AuthenticateRoute = ({ redirectPath = "/login", children }) => {
  const userDetail = getLocalStorage("user");
  const ssoToken = getSSOToken();
  const localToken = getLocalToken();

  if ((!ssoToken && !localToken) || !userDetail?.periodLicense) return <Navigate to={redirectPath} replace />;

  // period license validation routes
  if (userDetail?.levelName === "owner") {
    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);

    if (rangeOfDay <= 0) {
      destroyToken();
      removeLocalStorage("user");
      return <Navigate to={redirectPath} replace />
    };
  }

  return children;
};

export default AuthenticateRoute;