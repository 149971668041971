import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaBoxArchive } from "react-icons/fa6";
import { cn } from "../../utils/helper";
import { deleteProduct, getProductList } from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ConfirmDialog from "../../components/ConfirmDialog";

const Product = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productCurrentPage, setProductCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const productColumns = [
    { key: "sku_code", title: "Kode SKU" },
    { key: "name", title: "Nama Barang" },
    { key: "product_category", title: "Kategori", render: (value) => value?.name },
    { key: "product_unit", title: "Satuan", render: (value) => value?.name },
    { key: "purchase_price", title: "Harga Beli" },
    { key: "selling_price", title: "Harga Jual" },
    {
      key: "image",
      title: "Gambar",
      render: (value) => (
        <img src={value} className="h-8 mx-auto rounded" alt="" />
      )
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          onView={() => {
            setDataDetail(item);
            setOpenDetail(true);
          }}
          onEdit={() => navigate(`/product/form/${item?.id}`)}
          onDelete={() => {
            setDataDetail(item);
            setOpenConfirm(true);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetProductList();
  }, [])

  const onGetProductList = async () => {
    try {
      setIsLoading(true);
      const response = await getProductList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setProductList(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetProductList: ", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteProduct(dataDetail?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetProductList();
    } catch (error) {
      setIsDeleteLoading(false);
      console.log("Error on onConfirmDelete: ", error);
    }
  }

  const renderDetail = () => {
    const detailKeys = [
      { key: "sku_code", label: "Kode SKU" },
      { key: "serial_number", label: "Nomor Serial" },
      { key: "name", label: "Nama Produk" },
      { key: "product_category", key2: "name", label: "Kategori Produk" },
      { key: "product_unit", key2: "name", label: "Satuan Produk" },
      { key: "purchase_price", label: "Harga Beli" },
      { key: "selling_price", label: "Harga Jual" },
      { key: "description", label: "Deskripsi" }
    ];
    return detailKeys.map((item, index) => (
      <div key={index} className={cn("flex p-2 text-sm", { "bg-gray-200": (index % 2 === 0) })}>
        <div className="w-1/3 font-semibold">{item.label}</div>
        <div className="w-2/3">{dataDetail?.[item.key]?.[item.key2] || dataDetail?.[item.key]}</div>
      </div>
    ))
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Product</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => navigate("/product/form")}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isLoading}
            columns={productColumns}
            dataSource={productList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={productCurrentPage}
            total={!isLoading ? productList?.length : 0}
            onPageChange={(page) => setProductCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openDetail} onOpen={setOpenDetail} className={{ container: "max-w-2xl p-0" }}>
        <div className="flex items-center px-4 py-3 bg-sky-600">
          <div className="p-1 text-sm bg-white rounded-md text-sky-600">
            <FaBoxArchive />
          </div>
          <div className="ml-3 mr-1 text-lg font-semibold text-white">Detail Form</div>
        </div>
        <div className="px-8 py-6">
          <img src={dataDetail?.image} alt="" className="mx-auto rounded-lg max-h-40" />
          <div className="flex flex-col my-4">
            {renderDetail()}
          </div>
        </div>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default Product;