import axios from "axios";
import { destroyToken, getLocalToken, getSSOToken, removeLocalStorage } from "./jwt.service";

const validateUrl = (url) => {
  if (url && (url.includes("http://") || url.includes("https://"))) {
    return { baseURL: url, token: getSSOToken() ? `Bearer ${getSSOToken()}` : "" };
  }
  return { baseURL: `${process.env.REACT_APP_API_URL}/api/`, token: getLocalToken() ? `Bearer ${getLocalToken()}` : "" };
}

export const initializeAxios = () => {
  // Request interceptor
  axios.interceptors.request.use((config) => {
    config.baseURL = validateUrl(config.url).baseURL;
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Source: process.env.REACT_APP_URL,
      Name: "SiStok"
    };
    config.headers.Authorization = validateUrl(config.url).token;
    return config;
  }, (error) => Promise.reject(error));

  // Response interceptor
  axios.interceptors.response.use((response) => {
    return (response?.data?.data || response?.data?.message) ? response.data : response;
  }, (error) => {
    const message = error?.response?.data?.message || error?.response?.statusText || error?.message || error;
    if (message?.includes("Unauthenticated")) {
      destroyToken();
      removeLocalStorage("user");
      window.location.href = "/login";
    }
    return Promise.reject(message);
  });
}

export const userAvailabilitySSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/user-available`, { email });
}

export const registerSSO = async (requestData = {}) => {
  const headers = {
    ApiSource: process.env.REACT_APP_API_URL
  }
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/register_sso`, requestData, { headers: headers });
}

export const forgotPasswordSSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/forgot-password`, { email });
}

export const authRegister = async (requestData = {}) => {
  return await axios.post("auth/register", requestData);
}

export const authLogin = async (requestData = {}) => {
  return await axios.post("auth/login", requestData);
}

export const authChangePassword = async (requestData = {}) => {
  return await axios.put("auth/change-password", requestData);
}

export const authResetPassword = async (requestData = {}) => {
  return await axios.put("auth/reset-password", requestData);
}

export const getAnalytics = async (month, branchId) => {
  const queryParam = `?month=${month}&branchId=${branchId}`;
  return await axios.get(`analytics/admin${queryParam}`);
}

export const getUserList = async () => {
  return await axios.get("user/list");
}

export const updateUser = async (requestData = {}) => {
  return await axios.put("user/update", requestData);
}

export const getLicenseList = async () => {
  return await axios.get("user/license");
}

export const getStaffList = async () => {
  return await axios.get("user/staff");
}

export const getStaffDetail = async (id) => {
  return await axios.get(`user/staff/detail/${id}`);
}

export const createUpdateStaff = async (requestData = {}) => {
  return await axios.post("user/staff/create-update", requestData);
}

export const resetPasswordStaff = async (email) => {
  return await axios.post(`user/staff/reset-password`, { email });
}

export const verifiedStaff = async (requestData) => {
  return await axios.post(`user/staff/verified`, requestData);
}

export const deleteStaff = async (id) => {
  return await axios.delete(`user/staff/delete/${id}`);
}

export const getProductCategoryList = async () => {
  return await axios.get("product/category-list");
}

export const createUpdateProductCategory = async (requestData = {}) => {
  return await axios.post("product/category-create-update", requestData);
}

export const deleteProductCategory = async (id) => {
  return await axios.delete(`product/category-delete/${id}`);
}

export const getProductUnitList = async () => {
  return await axios.get("product/unit-list");
}

export const updateProductUnit = async (requestData = {}) => {
  return await axios.post("product/unit-create-update", requestData);
}

export const deleteProductUnit = async (id) => {
  return await axios.delete(`product/unit-delete/${id}`);
}

export const getProductList = async (ownerId) => {
  const queryParam = ownerId ? `?ownerId=${ownerId}` : "";
  return await axios.get(`product/list${queryParam}`);
}

export const getProductDetail = async (id) => {
  return await axios.get(`product/detail/${id}`);
}

export const createUpdateProduct = async (requestData = {}) => {
  return await axios.post("product/create-update", requestData);
}

export const deleteProduct = async (id) => {
  return await axios.delete(`product/delete/${id}`);
}

export const getStockCategoryList = async (type) => {
  return await axios.get(`stock/${type}/category-list`);
}

export const createUpdateStockCategory = async (requestData = {}) => {
  return await axios.post("stock/category-create-update", requestData);
}

export const deleteStockCategory = async (id) => {
  return await axios.delete(`stock/category-delete/${id}`);
}

export const getStockList = async (type) => {
  return await axios.get(`stock/${type}/list`);
}

export const createaStock = async (requestData = {}) => {
  return await axios.post("stock/create", requestData);
}

export const getSupplierList = async (ownerId) => {
  const queryParam = ownerId ? `?ownerId=${ownerId}` : "";
  return await axios.get(`supplier/list${queryParam}`);
}

export const createUpdateSupplier = async (requestData = {}) => {
  return await axios.post("supplier/create-update", requestData);
}

export const deleteSupplier = async (id) => {
  return await axios.delete(`supplier/delete/${id}`);
}

export const getBranchList = async (ownerId) => {
  const queryParam = ownerId ? `?ownerId=${ownerId}` : "";
  return await axios.get(`branch/list${queryParam}`);
}

export const createUpdateBranch = async (requestData = {}) => {
  return await axios.post("branch/create-update", requestData);
}

export const deleteBranch = async (id) => {
  return await axios.delete(`branch/delete/${id}`);
}

export const getReports = async (requestData = {}) => {
  return await axios.post(`report/results`, requestData);
}
