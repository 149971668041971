import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FaUserGroup, FaBuilding } from "react-icons/fa6";
import { RiInboxArchiveFill, RiInboxUnarchiveFill, RiArchive2Fill } from "react-icons/ri";
import { useAppContext } from '../../context/AppContextProvider';
import { getAnalytics, getBranchList } from "../../services/api.service";
import { getAllDatesInMonth } from "../../utils/helper";
import ManageOwner from "./ManageOwner";
import CardCount from "../../components/CardCount";
import Select from "../../components/Select";

const chartSizeOptions = [
  { name: "1000", value: { max: 1000, interval: 200 } },
  { name: "1500", value: { max: 1500, interval: 300 } },
  { name: "2500", value: { max: 2500, interval: 500 } }
];

const monthsOptions = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 }
];

const Dashboard = () => {
  const { userDetail } = useAppContext();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [analytics, setAnaytics] = useState({});
  const [charts, setCharts] = useState({});
  const [branchList, setBranchList] = useState([{ id: 0, name: "Semua Cabang" }]);
  const [branch, setBranch] = useState(0);
  const [chartSize, setChartSize] = useState({ max: 2500, interval: 500 });

  const isSuperAdmin = userDetail?.levelName === "superadmin";
  const isOwner = userDetail?.levelName === "owner";
  const isStaff = userDetail?.levelName === "staff";

  const options = {
    chart: {
      type: 'spline',
    },
    credits: {
      enabled: false
    },
    title: {
      text: undefined
    },
    xAxis: {
      categories: getAllDatesInMonth(month),
      labels: {
        formatter: function () {
          return this.value.formatted;
        }
      },
      rotation: 45, // Rotate the labels for better readability
      overflow: 'right'
    },
    yAxis: {
      title: {
        text: 'Nomor dari Stok Masuk / Keluar'
      },
      min: 0,
      max: chartSize?.max,
      tickInterval: chartSize?.interval,
    },
    tooltip: {
      headerFormat: null
    },
    series: [
      {
        name: "Stok Masuk",
        // data: [525, 1134, 385, 102, 684, 1296, 1195, 666, 1382, 942, 1145, 467, 1351, 224, 1459, 1196, 688, 1084, 1466, 808, 994, 347, 1371, 420, 1131, 228, 713, 539, 663, 1238, 663],
        data: charts?.stockInByDate || [],
        color: "rgb(37, 99, 235, .8)"
      },
      {
        name: "Stok Keluar",
        // data: [1388, 945, 327, 711, 130, 1020, 1222, 943, 1060, 1195, 555, 117, 1349, 513, 567, 681, 1449, 254, 1343, 1311, 1424, 387, 834, 504, 903, 1289, 426, 661, 1157, 1378, 663],
        data: charts?.stockOutByDate || [],
        color: 'rgb(13, 148, 136, .8)'
      }
    ]
  };

  useEffect(() => {
    onGetBranchList();
  }, [])

  useEffect(() => {
    if (month || branch) onGetAnalytics();
  }, [month, branch])

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics(month, branch);
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setAnaytics(response.data);
      onGenerateDataCharts(response.data?.stockByDates)
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  }

  const onGetBranchList = async () => {
    if (!isOwner) return;

    try {
      const response = await getBranchList();
      if (!response.success) throw response.message;

      setBranchList([...branchList, ...response.data]);
    } catch (error) {
      console.log("Error on onGetBranchList: ", error);
    }
  }

  const onGenerateDataCharts = (response = []) => {
    const dates = getAllDatesInMonth(month);
    let stockInByDate = [];
    let stockOutByDate = [];
    dates.forEach(date => {
      const dataByDate = response.find((item) => item.date === dayjs(date.origin).format('YYYY-MM-DD'));
      stockInByDate.push(dataByDate?.stock_in || 0);
      stockOutByDate.push(dataByDate?.stock_out || 0);
    });
    setCharts({ stockInByDate, stockOutByDate });
  }

  return (
    <div className="p-6">
      {isSuperAdmin &&
        <div className="flex gap-6">
          <div>
            <CardCount
              title="Jumlah User Registrasi"
              value={analytics?.userTotal || 0}
              icon={<FaUserGroup />}
              isLoading={fetchLoading}
              className={{
                container: "mb-6 from-sky-300 to-blue-600",
              }}
            />
            <CardCount
              title="Jumlah Cabang"
              value={analytics?.branchTotal || 0}
              icon={<FaBuilding />}
              isLoading={fetchLoading}
              className={{
                container: "from-emerald-300 to-teal-600",
              }}
            />
          </div>
          <ManageOwner />
        </div>
      }
      {(isOwner || isStaff) &&
        <div className="flex flex-col gap-6">
          <div className="flex gap-6">
            <CardCount
              title="Jumlah Stok Masuk"
              value={analytics?.stockInTotal || 0}
              icon={<RiInboxArchiveFill />}
              isLoading={fetchLoading}
              className={{
                container: "from-sky-300 to-blue-600 flex-1",
              }}
            />
            <CardCount
              title="Jumlah Stok Keluar"
              value={analytics?.stockOutTotal || 0}
              icon={<RiInboxUnarchiveFill />}
              isLoading={fetchLoading}
              className={{
                container: "from-emerald-300 to-teal-600 flex-1",
              }}
            />
            <CardCount
              title="Jumlah Sisa Stok"
              value={analytics?.remainingStock || 0}
              icon={<RiArchive2Fill />}
              isLoading={fetchLoading}
              className={{
                container: "from-orange-300 to-pink-600 flex-1",
              }}
            />
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex gap-4">
              <Select
                placeholder="Pilih Size"
                options={chartSizeOptions}
                defaultValue={chartSizeOptions[2]}
                className={{
                  container: "w-40 mb-4",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize"
                }}
                onInputChange={(e) => setChartSize(e.value)}
              />
              <Select
                placeholder="Pilih Bulan"
                options={monthsOptions}
                defaultValue={monthsOptions[month - 1]}
                className={{
                  container: "w-40 mb-4",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize"
                }}
                onInputChange={(e) => setMonth(e.value)}
              />
              {isOwner &&
                <Select
                  placeholder="Pilih Cabang"
                  options={branchList}
                  defaultValue={branchList[0]}
                  className={{
                    container: "w-1/5 mb-4",
                    input: "h-10 pl-3 capitalize",
                    suffix: "text-sky-700",
                    option: "capitalize"
                  }}
                  onInputChange={(e) => setBranch(e.id)}
                />
              }
            </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      }
    </div>
  )
}

export default Dashboard;