import { Fragment } from "react";
import { LoadingIcon } from './../assets/icon/SvgIconComponents';

const Table = ({ columns = [], loading = false, dataSource = [], exlucdeColumns = [] }) => {
  const colSpan = columns?.length || 1;

  const renderColumns = () => {
    if (!columns?.length) return "";

    return columns?.map((column, index) => {
      if (!column?.title || exlucdeColumns?.includes(column?.key)) return <Fragment key={index} />;
      return (
        <th key={index} scope="col" className="px-2 py-3 first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg">
          {column.title}
        </th>
      )
    })
  }

  const renderRowColumn = (item, itemIndex) => {
    if (!columns?.length) return "";
    return columns?.map((column, index) => {
      if (!column?.title || exlucdeColumns?.includes(column?.key)) return <Fragment key={index} />;
      if (typeof column.render === "function") {
        return (
          <td key={index} className="px-2 py-3 text-center whitespace-nowrap">
            {column.render(item[column?.key] || "-", item, itemIndex)}
          </td>
        )
      }
      return (
        <td key={index} className="px-2 py-3 text-center whitespace-nowrap">
          {item[column?.key] || "-"}
        </td>
      )
    })
  }

  const renderRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={colSpan} className="px-6 py-4 text-center">
            <span className="flex items-center justify-center">
              <LoadingIcon className="w-4 h-4 mr-2" /> Loading...
            </span>
          </td>
        </tr>
      )
    }

    if (dataSource?.length) {
      return dataSource?.map((item, index) => {
        return (
          <tr key={index} className="hover:bg-gray-100">
            {renderRowColumn(item, index)}
          </tr>
        )
      })
    }
    return (
      <tr>
        <td className="px-6 py-4 font-medium text-center w-max" colSpan={colSpan}>
          Data Not Found
        </td>
      </tr>
    )
  }

  return (
    <table className="w-full rounded-lg ring-1 ring-sky-600">
      <thead className="text-sm font-semibold text-gray-900">
        <tr className="text-white border-b border-sky-600 bg-sky-600">
          {renderColumns()}
        </tr>
      </thead>
      <tbody className="text-xs text-sky-700">
        {renderRows()}
      </tbody>
    </table>
  )
}

export default Table;