import { useEffect, useState } from "react";
import { FaPeopleCarryBox, FaPen, FaPlus, FaTrashCan } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { createUpdateSupplier, deleteSupplier, getSupplierList } from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import ConfirmDialog from "../../components/ConfirmDialog";

const Suplier = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierCurrentPage, setSupplierCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [isCreateUpdateLoading, setIsCreateUpdateLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { register, setValue, formState: { errors }, reset, clearErrors, handleSubmit } = useForm();

  const supplierColumns = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Nama" },
    { key: "phone_number", title: "No. Handphone" },
    { key: "address", title: "Alamat" },
    { key: "information", title: "Keterangan" },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            setOpenForm(true);
            setDataDetail(item);
            onChange("category", item?.category);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setDataDetail(item);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetSupplierList();
  }, [])

  const onGetSupplierList = async () => {
    try {
      setIsLoading(true);
      const response = await getSupplierList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setSupplierList(response?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetSupplierList: ", error);
    }
  }

  const onCreateUpdateSupplier = async (data) => {
    try {
      setIsCreateUpdateLoading(true);
      const request = {
        id: dataDetail?.id,
        name: data?.name || dataDetail?.name,
        phoneNumber: data?.phoneNumber || dataDetail?.phone_number,
        address: data?.address || dataDetail?.address,
        information: data?.information || dataDetail?.information
      }
      const response = await createUpdateSupplier(request);
      if (!response.success) throw response.message;

      setIsCreateUpdateLoading(false);
      setOpenForm(false);
      onGetSupplierList();
    } catch (error) {
      setIsCreateUpdateLoading(false);
      console.log("Error on onCreateUpdateSupplier: ", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteSupplier(dataDetail?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetSupplierList();
    } catch (error) {
      setIsDeleteLoading(false);
      console.log("Error on onConfirmDelete: ", error);
    }
  }

  const onCreateBtnClick = () => {
    reset();
    clearErrors();
    setOpenForm(true);
    setDataDetail({});
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Suplier</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onCreateBtnClick}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isLoading}
            columns={supplierColumns}
            dataSource={supplierList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={supplierCurrentPage}
            total={!isLoading ? supplierList?.length : 0}
            onPageChange={(page) => setSupplierCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openForm} onOpen={setOpenForm} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onCreateUpdateSupplier)}>
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              {dataDetail?.id ? <FaPen /> : <FaPeopleCarryBox />}
            </div>
            <div className="ml-3 mr-1 text-lg">{dataDetail?.id ? "Edit" : "Tambah"}</div>
            {dataDetail?.id &&
              <h1 className="mx-1 text-sm">(&nbsp;{dataDetail?.name}&nbsp;)</h1>
            }
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                label="Nama"
                placeholder="Masukkan Nama"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.name?.message}
                onInputChange={(e) => onChange('name', e.target.value)}
                {...register("name", { required: "Nama wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="No. Handphone"
                placeholder="Masukkan No. Handphone"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.phoneNumber?.message}
                onInputChange={(e) => onChange('phoneNumber', e.target.value)}
                {...register("phoneNumber", { required: "No. Handphone wajib diisi!", pattern: { value: /^[0-9]*$/, message: "No. Handphone tidak sesuai!" } })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="Alamat"
                placeholder="Masukkan Alamat"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.address?.message}
                onInputChange={(e) => onChange('address', e.target.value)}
                {...register("address", { required: "Alamat wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="Keterangan"
                placeholder="Masukkan Keterangan"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3"
                }}
                errorMessage={errors?.information?.message}
                onInputChange={(e) => onChange('information', e.target.value)}
                {...register("information")}
              />
            </div>
            <Button label="submit" isLoading={isCreateUpdateLoading} className="w-1/3 h-10 mt-8 ml-auto" />
          </div>
        </form>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default Suplier;