import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { initializeAxios } from "../services/api.service";
import AuthenticateRoute from "./AuthenticateRoute";
import Login from "../pages/authenticate/Login";
import Register from "../pages/authenticate/Register";
import ForgotPassword from "../pages/authenticate/ForgotPassword";
import Main from "../layout/Main";
import Dashboard from "../pages/analytics/Dashboard";
import ManageStock from "../pages/stock/ManageStock";
import ManageProduct from "../pages/product/ManageProduct";
import Supplier from "../pages/supplier/Supplier";
import Product from "../pages/product/Product";
import ProductForm from "../pages/product/ProductForm";
import Stock from "../pages/stock/Stock";
import StockForm from "../pages/stock/StockForm";
import ManageBranch from "../pages/branch/ManageBranch";
import ManageStaff from "../pages/staff/ManageStaff";
import StaffForm from "../pages/staff/StaffForm";
import Report from "../pages/analytics/Report";

const RoutesManager = () => {
  initializeAxios();

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="/" element={
          <AuthenticateRoute>
            <Main />
          </AuthenticateRoute>
        }>
          <Route index path="dashboard" element={<Dashboard />} />
          <Route path="category-stock/:type" element={<ManageStock />} />
          <Route path="manage-product" element={<ManageProduct />} />
          <Route path="suplier" element={<Supplier />} />
          <Route path="product" element={<Outlet />}>
            <Route index element={<Product />} />
            <Route path="form" element={<ProductForm />} />
            <Route path="form/:productId" element={<ProductForm />} />
          </Route>
          <Route path="stock/:type" element={<Outlet />}>
            <Route index element={<Stock />} />
            <Route path="form" element={<StockForm />} />
          </Route>
          <Route path="branch" element={<ManageBranch />} />
          <Route path="staff" element={<Outlet />}>
            <Route index element={<ManageStaff />} />
            <Route path="form/staff" element={<StaffForm />} />
            <Route path="form/staff/:staffId" element={<StaffForm />} />
          </Route>
          <Route path="report" element={<Report />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-reset/:token" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="login" replace />} />
      </Route>
    </Routes>
  );
};

export default RoutesManager;